import React, { Component } from 'react';
class BlogComponent extends Component{
    render() 
    { 
      
        return ( 
           < React.Fragment> 
           <div className="mobileAppimg">
           <img src="/assets/images/Slice.png" width={1340} />
           </div>
           <div class="mobileApp">
           <span class="width ml-5" ></span>
           </div>
           </React.Fragment>
        )

}
}

export default BlogComponent;