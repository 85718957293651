import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './footerbar.css';

 class Fotterbar extends Component  {
	 state={
		open:false,
		 setOpen:false
	 }

	
render(){
	return (
		<div className="card footbar">
		   <div className=" col-md-12 footer_main d-flex justify-content-center"  >
            <div className="row col-md-12">
            
		
            <div className="    pt-4">
             <div className=" get_in_touch_content   ">
               <span className=" pb-1 policy_procedure_text d-flex justify-content-center" style={{"text-align":"center"}}>
BS-719,7th Floor, Galaxy Diamond Plaza, TechZone-IV,<br />
</span>
<span className='d-flex justify-content-center policy_procedure_text' style={{"text-align":"center"}}> Greater Noida, Sector 2, UttarPradesh – 201306</span>
         
			 <div className="get_in_touch_content pt-2 d-flex justify-content-center" style={{"text-align":"center"}}>
               <span className="policy_procedure_text">Email: support@linuxmantra.com </span>
               
             </div>
			 <div className="get_in_touch_content pt-2 d-flex justify-content-center" style={{"text-align":"center"}}>
               <span className="policy_procedure_text">Call Us +91-1205110884</span>
               
             </div>
			
			 
			 <div className=" pt-3 get_in_touch_content pt-2 d-flex justify-content-center">
               {/* <span className="policy_procedure_text">
                Application Software Cloud Computing Mobile App Development Cyber Security</span> */}
               
             </div>
            </div>
			<div align=" right " className="right">
                  <a href="https://linuxmantra.com" >Privacy Policy</a>
                 <br /><br />
                </div>
           </div>
		  
         </div>
		 
		</div>
		</div>
		
	);
}
 }
export default Fotterbar;