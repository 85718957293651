import React, { Component } from 'react';
class SignInComponent extends Component{
    render() 
    { 
      
        return ( 
           < React.Fragment> 
           SignIn
           </React.Fragment>
        )

}
}

export default SignInComponent;