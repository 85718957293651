import React, { Component } from 'react';


import './home.css';
class ContactComponent extends Component{
  
    render() 
    { 
      
        return ( 
           < React.Fragment> 
            <div className="row   col-md-12">
            <div className=" col-*">
            <div className=" contactimg col-*">
  <div className=" ">
<div className="contactsd  col-md-6">
  <br />
<span  className=" specheader col-* "> Customer Service </span><br /><br/>
<span  className=" spec col-*   "> Phone: +91-1205110884</span><br />
<span  className=" spec col-*  " style={{"whiteSpace":"nowrap"}}>E-mail:support@linuxmantra.com</span>
{/*<span className="col-*-10 contactButton "  > CONTACT US </span>*/}

</div>
{/* <div   className="  col-md-6">
<br />
<span  className=" specheader col-*  "> Business Relations </span><br/><br/>
<span  className=" spec col-* "> Phone: +91-9953760745</span><br />
<span  className=" spec col-*  "> E-mail: info@gogedi.com</span><br />


</div> */}
              
</div>
              
            </div>
            
             </div>
           
             </div>

            


            
           </React.Fragment>
        )

}
}

export default ContactComponent;