import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

 class Navbar extends Component  {
	
	state={
		open:false,
		 setOpen:false
	 }

	handleClick = () => {
		this.state.setOpen=!this.state.open;
	};

	closeMenu = () => {
		this.state.setOpen=false;
	};



render(){
	return (
		<nav className="navbar col-* pl-0">
			<div onClick={this.handleClick()} className="nav-logo ml-0 col-lg-3">
			<Link to="/customer/home" >
				<img alt="" className=" linuxMLogo pt-0 mt-0 ml-0 pl-0" src={process.env.PUBLIC_URL + "/assets/images/linux-matra3-final-01-1.png"} width="135px"/>
			</Link>
			
			</div>
			<div className="link navigation-links col-lg-6">
				
			<ul className={this.state.open ? 'nav-links active' : 'nav-links'}>
			{/* <li className="nav-item">
					<Link to="/customer/home" className="nav-link" onClick={this.closeMenu()}>
						HOME
					</Link>
				</li> */}
			{/* <li className="nav-item">
					<Link  className="nav-link" to="/customer/home" onClick={this.closeMenu()}>
						ABOUT
					</Link>
				</li> */}
				{/* <li className="nav-item"><a href="#">
					<Link  className="nav-link" to="/customer/home" onClick={this.closeMenu()}>
						IT SERVICES
					</Link></a>
			    <div class="dropdown">
				<ul>
			    <li ><a  href="#"><Link to="/customer/home">Application Software Development</Link></a></li>
				<li><a href="#"><Link to="/customer/home">Cloud Computing</Link> </a></li>
				<li><a href="#"><Link to="/customer/home">Moble App Development</Link> </a></li>
				<li><a href="#"><Link to="/customer/home">Cyber Security</Link></a></li>
				<li><a href="#"><Link to="/customer/home">Digital Media Marketing</Link> </a></li>
				<li><a href="#"><Link to="/customer/home">Database management Services</Link> </a></li>
				</ul>
				</div>
				</li> */}
				
				
				{/* <li className="nav-item">
					<Link to="/customer/home" className="nav-link" onClick={this.closeMenu()}>
						RESOURCES
					</Link>
				</li> */}
				{/*<li className="nav-item">
					<Link to="/customer/blog" className="nav-link" onClick={this.closeMenu()}>
						BLOG
					</Link>
	</li>*/}

            {/* <li className="nav-item">
					<Link to="/customer/contact" className="nav-link" onClick={this.closeMenu()}>
						CONTACT&nbsp;US
					</Link>
				</li>
				<li className="nav-item">
					
				</li>
				<li className="nav-item">
					
				</li> */}
				
				{/* <li className="nav-item" >
					<span className='d-flex justify-content-center'>
				<img src="/assets/images/designer/7a147867815c53f652b2b8288b1099c4.jpg" height={60} style={{"width":"100%","border-radius":"6px","border":"2px solid white"}}/>
				<img src="/assets/images/designer/7a147867815c53f652b2b8288b1099c4.jpg" height={60} style={{"width":"100%","border-radius":"6px"}}/>
				<img src="/assets/images/designer/7a147867815c53f652b2b8288b1099c4.jpg" height={60} style={{"width":"100%","border-radius":"6px"}}/>
				</span>
				</li> */}
				
				
			</ul>
			<div className="col-sm-12 mt-4 mb-4 hideOnmobile" align="end">
            <span className="col-sm-12 mt-4 " ><Link className="Link contactC" to="/customer/contact"> CONTACT US </Link></span>
            </div> 
			</div>
		{/*<div className="sign_up_container  d-flex justify-content-center col-md-2" >
			<span  onClick={this.handleClick()} className="sign_up">
			<Link to="/customer/signup" >
			SIGN UP
			</Link>
			</span>
	</div>*/}
	<div className="sink">
	    <li className="buttonable">
		<span  className="nav-link" onClick={this.closeMenu()}>
						<img src="/assets/images/menuicon.jpg" width={80} />
					</span>
					
					<div class="dropmenu  ">
				<ul>
				<li >
					<Link to="/customer/home" className="nav-link" onClick={this.closeMenu()}>
						HOME
					</Link>
				</li>
				{/* <li >
					<Link  className="nav-link" to="/customer/home" onClick={this.closeMenu()}>
						ABOUT
					</Link>
				</li> */}
			    {/* <li><a  href="#"><Link to="/customer/home">IT SERVICES</Link></a>
				<div class="menulist">
				<ul>
			    <li ><a  href="#"><Link to="/customer/home">Application Software Development</Link></a></li>
				<li><a href="#"><Link to="/customer/home">Cloud Computing</Link> </a></li>
				<li><a href="#"><Link to="/customer/home">Moble App Development</Link> </a></li>
				<li><a href="#"><Link to="/customer/home">Cyber Security</Link></a></li>
				<li><a href="#"><Link to="/customer/home">Digital Media Marketing</Link> </a></li>
				<li><a href="#"><Link to="/customer/home">Database management Services</Link> </a></li>
				<br />
				</ul>
				</div>
				</li> */}
				
			    {/* <li><a  href="#"><Link to="/customer/home">RESOURCES</Link></a></li> */}

				<li><a href="#"><Link to="/customer/contact">CONTACT US</Link> </a></li>
				<br />
				
				
				</ul>
				</div>
				
				</li>
				</div>
		</nav>
		
		
	);
}
};

export default Navbar;