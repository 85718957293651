import React, { Component } from 'react';
class SignUpComponent extends Component{
    render() 
    { 
      
        return ( 
           < React.Fragment> 
           SignUp
           </React.Fragment>
        )

}
}

export default SignUpComponent;