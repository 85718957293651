import './App.css';
import { BrowserRouter as Router, Switch,Route,Redirect } from "react-router-dom"
import 'typeface-roboto';
import PortalComponent from "./components/portal.router.component"
import CustomerRouterComponent from './components/customer.router.component';
function App() {
  return (
    <Router>
        <Switch>
          <Redirect from="/" exact to="/customer/home"/>
          <Route path="/customer" component={CustomerRouterComponent}></Route>
          <Route path="/portal" component={PortalComponent}></Route>
          
        </Switch>
      </Router>
  );
}

export default App;
