import React, { Component } from 'react';
import './home.css';
import { Link } from 'react-router-dom';

class HomeComponent extends Component{
    render() 
    { 
        return ( 
          
           < React.Fragment>
            
           <div className="homeSize col-*">
 
           <div className=" col-*">
           <div className="   col-sm-12">
            <div className="  col-sm-12 mt-0 mb-0 ml-0 mr-0">
            {/* <div className=" evimg mt-4 col-sm-12 d-flex justify-content-start mt-2 mb-0 pb-0 ml-0 mr-0"> */}
              {/* <div>
            <div className='col-sm-2 round'>
            <img className='col-sm-12 ' src="/assets/images/designer/digitalmarketingdevelopment.png" height={300}/>

            </div> */}
            {/* <video autoPlay muted loop id="myVideo" style={{"width":"100%"}} className='display'>
          <source src="/assets/images/designer/25.mp4" type="video/mp4" />
        </video> */}
             
             {/* </div> */}
             
             
              
              
            {/* </div> */}
            
             </div>
             
             {/* <div className="col-sm-7  mt-0 pt-1 mr-0 pr-0">
             
             </div> */}
             </div>
            
             {/* <div align=" right " className="col-sm-12 mb-0 mt-0 pt-0 pr-0  ">
              <button className="col-sm-12  cta" ><Link className="Link " to="/customer/contact"> CONTACT US </Link></button>
              
              </div> */}
          

            

           </div>
          
            <div className=' ourService pb-3 '>
            <div className='col-sm-12 d-flex justify-content-center mt-3' style={{"color":"#20386D","font-weight":"700","font-size":"35px"}}>WE OFFER</div>
<div className='col-sm-12 mt-3 d-flex justify-content-center'>
            <div className='col-sm-12 row'>
          <div className='col-sm-3 mainboxDiv mt-4' >
            <img className='col-sm-12 ' src="/assets/images/designer/webapplicationdeveplopment.png" height={300} style={{"width":"100%"}}/>
          <strong className="fontHover" >Web Application Development</strong></div>
          <div className='col-sm-3 mainboxDiv mt-4' >
            <img className='col-sm-12 ' src="/assets/images/designer/Mobileappdevelopment.png" height={300}/>
          <strong className="fontHover" >Mobile App Development</strong></div>

          <div className='col-sm-3 mainboxDiv mt-4' >
            <img className='col-sm-12 ' src="/assets/images/designer/Cybersecurity.png" height={300}/>
          <strong className="fontHover" >InfoSec & Automation Services</strong></div>
          <div className='col-sm-3 mainboxDiv mt-4' >
            <img className='col-sm-12 ' src="/assets/images/designer/network.png" height={300}/>
          <strong className="fontHover" >DevOps Services </strong></div>


            </div>
            
            </div>
            {/* <div className="col-sm-12 mt-4 mb-4 pt-4" align="center">
            <span className="col-sm-12 mt-4 " ><Link className="Link contactUs" to="/customer/contact"> CONTACT US </Link></span>
            </div> */}
            </div>


           <div className='  OurFuture '>

           <div className='col-sm-12 d-flex justify-content-center mt-3' style={{"color":"#20386D","font-weight":"700","font-size":"35px","text-align":"center"}}>Envisioning Our Future</div>
           <div className=' col-sm-12 d-flex justify-content-center mt-2' style={{"color":"#20386D","font-weight":"400","font-size":"20px"}}>
            <div className='col-sm-8 ' style={{"text-align":"center"}}>We empower Businesses to harness cutting-edge digital technologies, fortifying their capabilities and unlocking new growth avenues in the dynamic digital landscape of today.</div>
            </div>
            </div>


            <div className='  ourService '>

<div className='col-sm-12 d-flex justify-content-center mt-3' style={{"color":"#20386D","font-weight":"700","font-size":"35px","text-align":"center"}}>Our Technological Capabilities</div>
<div className=' col-sm-12 d-flex justify-content-center mt-2' style={{"color":"#20386D","font-weight":"400","font-size":"20px"}}>

<div className='col-sm-12 row '>
  <div className='col-sm-4 mt-4  mainboxDiv'> 
  <img src="/assets/images/designer/frontend.png" height={300} style={{"width":"100%"}}/>
  </div>
  <div className='col-sm-4 mt-4 mainboxDiv'> 
  <img src="/assets/images/designer/backend.png" height={300} style={{"width":"100%"}}/>
  </div>
  <div className='col-sm-4 mt-4 mainboxDiv'> 
  <img src="/assets/images/designer/Databasservers.png" height={300} style={{"width":"100%"}}/>
  </div>
  
</div>


 </div>

 </div>
 <div className='  OurFuture '>

<div className='col-sm-12 d-flex justify-content-center ' style={{"color":"#20386D","font-weight":"700","font-size":"35px","text-align":"center"}}>Our Success Blossoms with Our Customers' Triumphs</div>
<div className=' col-sm-12 d-flex justify-content-center ' style={{"color":"#20386D","font-weight":"400","font-size":"20px"}}>
  <span className='mt-4 ' style={{"align-self":"center"}}>   
     <img style={{"padding-right":"2vw","width":"20%"}}  src="/assets/images/world.jpeg" />
  <img style={{"padding-right":"2vw","width":"20%"}} className='pl-3' src="/assets/images/yatra_logo.svg" />
  <img style={{"padding-right":"2vw","width":"20%"}} className='pl-3' src="/assets/images/designer/naukri.png" />
  <img style={{"padding-right":"2vw","width":"20%"}} className='pl-3' src="/assets/images/designer/radius_logo.png" />

      <img style={{"width":"20%"}} src="/assets/images/glida_logo.png" />

</span>
 </div>
 </div>

           



 </div>
           </React.Fragment>
        )
}
}

export default HomeComponent;